import React from 'react';


class WhyUsPage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center ">
                  <div className="services">
                    <div className="desc">
                      <h2>My page header title</h2>
                      <p>My description</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhyUsPage;
