import React from 'react';

class Footer extends React.Component {
  render () {
    let date = new Date()
    return (

      <footer id="colorlib-footer">
        <div className="container">
          <div className="row row-pb-md">
            <div className="col-md-3 colorlib-widget">
              <h4>About chatbots</h4>
              <p>Virtual agents take care of the tedious and repetitive tasks that steal
                time and drain energy from support teams and customers alike.</p>
                <ul className="colorlib-social-icons">
                  <li><a href="#"><i className="icon-twitter"></i></a></li>
                  <li><a href="#"><i className="icon-facebook"></i></a></li>
                </ul>
            </div>

            <div className="col-md-3 colorlib-widget">
              <h4>Solutions</h4>
              <ul className="colorlib-footer-links">
                  <li><a href="/agent-bot">Agent bot</a></li>
                  <li><a href="/voice">Voice</a></li>
                  <li><a href="/live">Live</a></li>
                </ul>
            </div>

            <div className="col-md-3 colorlib-widget">
              <h4>Company</h4>
              <ul className="colorlib-footer-links">
                  <li><a href="/why-us">Why us</a></li>
                  <li><a href="/what-we-do">What we do</a></li>
                  <li><a href="/use-cases">Use cases</a></li>
                  <li><a href="/how-works">How it works</a></li>
                </ul>
            </div>

            <div className="col-md-3 colorlib-widget">
              <h4>Contact Info</h4>
              <ul className="colorlib-footer-links">
                <li>Made with ❤️ to your startup</li>
                <li><a href="tel://1234567920"><i className="icon-phone"></i> +57 305 8126314</a></li>
                <li><a href="mailto:info@sisibot.com"><i className="icon-envelope"></i> info@sisibot.com</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copy">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Sisi Bot™ is a trademark used under license. <a href="https://www.virtualcapitalofamerica.com/" target="_blank" className="text-gold">VCA Ventures</a> Copyright @ {date.getFullYear()}. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

export default Footer;
