import React from 'react';


class PricingPage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="colorlib-services colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-lightbulb"></i>
                    </span>
                    <div className="desc">
                      <h3>Create your own conversation flows</h3>
                      <p>Train your machine with triggers and possible answers to your customers, also
                        you can create sequence to provide logical conversations.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-beaker"></i>
                    </span>
                    <div className="desc">
                      <h3>Supervised learning to add personality</h3>
                      <p>Your knowledge is yours, all flows can be designed to provide personality
                        to your bot.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-layers"></i>
                    </span>
                    <div className="desc">
                      <h3>Conversation Builder with flows</h3>
                      <p>Is possible create tons of layers of flows to concatenate conversations and
                        provide a natural conversation, also you can integrate it with third party services.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PricingPage;
