import React from 'react';


class WhatWeDoPage extends React.Component {
  render() {
    return (
      <>
        <div className="gobot-container py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 grid-margin mx-auto d-block">
                <div className="card">
                  <div className="card-body">
                    <div className="container pt-2">
                      <h4 className="card-title">My page header title</h4>
                      <p className="card-description">My description</p>
                      <button className="btn btn-primary mr-2">[Call to action]</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDoPage;
