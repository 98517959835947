import React from 'react';
import { Outlet } from "react-router-dom";

import TopNavigation from '../components/navigation/_navGeneral'
import Footer from '../components/footer/_footerGeneral'

class Layout extends React.Component {
  render() {
    return (
      <>
        <section>
          <TopNavigation {...this.props} />

          {/* Start Content */}
          <Outlet />
          {/* End Content */}

          <Footer {...this.props} />
        </section>

        <div id="ftco-loader" className="ftco-loader show fullscreen"><svg className="circular" width="48px" height="48px">
          <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" />
          <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10"
            stroke="#4c9cf9" />
        </svg>
        </div>

        <div className="gototop js-top">
          <a href="#" className="js-gotop"><i className="icon-arrow-up2"></i></a>
        </div>

        <section className="pwa-prompt banner-bottom-center">
          <div className="d-flex flex-row justify-content-center position-relative">
            <div className="d-flex align-items-center col-6">
              <div className="ath-banner-cell">
                <img width="48" src="/img/favicon-96x96.png" alt="PWA" className="ath-prompt-logo" />
              </div>
              <p className="w-100">Do you want install Sisi Bot?</p>
            </div>
            <div className="d-flex align-items-center justify-content-end col-6">
              <button className="btn btn-notnow btn-outline-light btn-fw mx-2">Not Now</button>
              <button className="btn btn-install btn-warning mx-2">Install</button>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Layout;
