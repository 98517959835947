import React from 'react';

class Footer extends React.Component {
  render () {
    return (

      <footer>
        {/* TODO: */}
      </footer>

    );
  }
}

export default Footer;
