import React from 'react';


class UseCases extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center ">
                  <div className="services">
                    <div className="desc">
                      <h3>Using AI to make online experience simple for everyone</h3>
                      <p>Virtual agents take care of the tedious and repetitive tasks that steal time and drain energy from support teams and customers alike.</p>
                      <p>Whether you are looking to improve customer experience, or work smarter as a team, conversational AI should be at the heart of your digital strategy. A virtual agent can deliver amazing results, and help win the hearts and minds of customers who interact with your brand on a daily basis.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>A digital employee with unlimited potential</h3>
                    <p>Companies are helping to turbo-charge employee productivity with conversational AI.</p>
                    <p>Imagine what your team can achieve with a virtual colleague who can answer questions instantly, perform tasks on your behalf, and put you in touch with the right person to keep a project moving forward.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 colorlib-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Always accessible and instantaneous citizen assistance </h3>
                    <p>Citizens want everyday life to be easy with fast and easy online interactions with government and public services.</p>
                    <p>With conversational AI, local, regional and national branches of governments, and companies in the public sector, are making themselves more accessible than ever to millions of people across 90+ organizations in the Nordics.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Conversational AI in local governance</h3>
                    <p>Connected citizens now enjoy instant answers to questions about public services and easier navigation through the jungle of public data including fast-tracks to which forms to fill out.</p>
                    <p>Human public officials are liberated from spending endless hours on repetitive work, and can focus their attention on delivering a personalized and high-level service experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 colorlib-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Conversational banking is all about the experience </h3>
                    <p>Customers expect you to be available at all times with frictionless and instant online support. </p>
                    <p>A virtual agent powered by conversational AI is an easy way to both meet and exceed customer expectations today - and future-proof your business at the same time. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Bringing AI into telecommunication</h3>
                    <p>For telecom companies, a fast, easy and convenient customer experience is essential for business.</p>
                    <p>Industry leaders know that seamless customer interactions are key to their success, which is why companies that are embracing AI are reaping the benefits of increased revenue, customer satisfaction and loyalty.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 colorlib-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Human assistance, on call</h3>
                    <p>Human support when needed. A virtual agent for everything else.</p>
                    <p>Conversational AI is masterful at answering questions about your business and automating the kinds of interactions that would take up precious time for human employees. For more nuanced queries, your virtual agent is also smart enough to know what it doesn’t understand and seamlessly transfer customers to someone who can help.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UseCases;
