import React from 'react';


class VoicePage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center ">
                  <div className="services">
                    <div className="desc">
                      <h3>Your customers use natural and informal language.</h3>
                      <h5>Let them be themselves.</h5>
                      <p>People talk naturally, even to companies. Voice’s natural language understanding
                        engine adds a layer to interpret conversational language and learns from every
                        interaction. Voice integrates empathy and human-like dialogues in customer service
                        communication.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VoicePage;
