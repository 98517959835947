import React from 'react';


class LandingIndexPage extends React.Component {
  render() {

    return (
      <>
        {/* Hero */}
        <section id="home" className="video-hero" data-section="home">
          <div className="display-t">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 mb-5 pb-5">
                  <h1 className="title">Growth your business with automation</h1>
                  <p>Deliver quick, richer experiences to your customers with omnichannel solutions, a single customer interface, and AI-powered messaging.</p>
                  <p><a href="https://app.sisibot.com/signup" className="btn btn-danger btn-custom with-arrow btn-lg shadow">
                    <span className="mr-1">Start Automating Now</span>
                    <i className="ml-1 icon-arrow-right3"></i></a></p>
                </div>
                <div className="d-none d-md-block col-md-6">
                  <img className="img-fluid d-block mx-auto" src="/images/heros/main.png" alt="" width="450" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Logos */}
        <div className="colorlib-intro bg-danger py-5">
          <div className="container py-5">
            <div className="row d-flex justify-content-center align-content-stretch flex-wrap">
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/hopper-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/stella-dot-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/ring-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/skims-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/rappi-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/glossier-logo.png" height="45" />
              </div>
              <div className="col-3 my-3">
                <img alt="demo logo" className="companies-list-item-2 d-block mx-auto" src="/images/logos/sweetgreen-logo.png" height="45" />
              </div>
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="colorlib-intro">
          <div className="container">
            <div className="row d-flex">
              <div className="col-12">
                <h2 className="text-center mb-5 pb-3">What we do?</h2>
              </div>
              <div className="col-md-4 text-center ">
                <div className="services card shadow h-100">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <span className="icon mt-5">
                      <i className="icon-lightbulb text-danger"></i>
                    </span>
                    <div className="desc">
                      <h3>Smart flows</h3>
                      <p>Train your machine with triggers and possible answers to your customers, also
                        you can create sequence to provide logical conversations.</p>
                    </div>
                    <div>
                      <p><a href="#" className="btn text-danger">Learn more</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center ">
                <div className="services card shadow h-100">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <span className="icon mt-5">
                      <i className="icon-beaker text-danger"></i>
                    </span>
                    <div className="desc">
                      <h3>Artificial Intelligence</h3>
                      <p>Your knowledge is yours, all flows can be designed to provide personality
                        to your bot.</p>
                    </div>
                    <div>
                      <p><a href="#" className="btn text-danger">Learn more</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center ">
                <div className="services card shadow h-100">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <span className="icon mt-5">
                      <i className="icon-layers text-danger"></i>
                    </span>
                    <div className="desc">
                      <h3>Conversation Builder</h3>
                      <p>Is possible create tons of layers of flows to concatenate conversations and
                        provide a natural conversation, also you can integrate it with third party services.</p>
                    </div>
                    <div>
                      <p><a href="#" className="btn text-danger">Learn more</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Video */}
        <div className="bg-light colorlib-intro shadow video-hero">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 text-center colorlib-heading ">
                <h2 className="text-danger">Understand your customers like never before</h2>
                <p className="">People talk naturally, even to companies.
                  AgentBot’s natural language understanding
                  engine allows it to interpret conversational
                  language and it learns from every interaction.
                  AgentBot is an Artificial Intelligence solution
                  that integrates human empathy and human-like
                  dialogues in customer service communication.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <span className="play">
                  <a href="https://www.youtube.com/watch?v=DMPFGaaSOE0" className="pulse popup-vimeo">
                    <i className="icon-play3"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Slider */}
        <div className="colorlib-featured overflow-hidden py-5">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center mb-5 pb-3">Take a look</h3>
            </div>
            <div className="featured-wrap">
              <div className="owl-carousel">
                <div className="item d-flex justify-content-center">
                  <div className="col-md-8">
                    <div className="featured-entry">
                      <img className="img-fluid" src="/images/dashboard_full_1.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="item d-flex justify-content-center">
                  <div className="col-md-8">
                    <div className="featured-entry">
                      <img className="img-fluid" src="/images/dashboard_full_2.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="item d-flex justify-content-center">
                  <div className="col-md-8">
                    <div className="featured-entry">
                      <img className="img-fluid" src="/images/dashboard_full_3.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Key points 1 */}
        <div className="colorlib-work-featured colorlib-bg-white mb-0 shadow">
          <div className="container">
            <div className="row mobile-wrap d-flex justify-content-between">
              <div className="col-md-6">
                <div className="desc">
                  <h2 className="text-center">Scale your business with AI Chatbots</h2>
                  <div className="features">
                    <span className="icon"><i className="icon-chat"></i></span>
                    <div className="f-desc">
                      <p><strong>Create omnichannel experiences Anytime, anywhere.</strong> What channels do your customers prefer?
                        Provide the same experience across any text or voice channel. AgentBot’s middleware interacts
                        with each application and adapts the answers to build coherent, evolving conversations.</p>
                    </div>
                  </div>
                  <div className="features">
                    <span className="icon"><i className="icon-bargraph"></i></span>
                    <div className="f-desc">
                      <p><strong>Make better decisions. Improve your customer service.</strong> Monitor your performance through the
                        wide range of quantitative and qualitative real time analytics and discover useful insights
                        to help deliver better experiences.</p>
                    </div>
                  </div>
                  <p className="text-center">
                    <a href="https://app.sisibot.com/signup" className="btn btn-danger btn-lg shadow with-arrow">Improve your business <i className="icon-arrow-right3"></i>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img src="/images/features/chatbots-1.webp" alt="Sisi Bot Example" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        {/* Key points 2 */}
        <div className="colorlib-work-featured pb-0">
          <div className="container">
            <div className="row mobile-wrap d-flex justify-content-between">
              <div className="col-md-6">
                <img src="/images/features/support.png" alt="Sisi Bot Example" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <div className="desc">
                  <h2 className="text-center">Improve loyalty in your customers</h2>
                  <div className="features">
                    <span className="icon"><i className="icon-lightbulb"></i></span>
                    <div className="f-desc">
                      <p><strong>Empower your agents. Artificial intelligence for better answers.</strong> Live
                        uses artificial intelligence to
                        learn from previous interactions and evolve with use. Live offers
                        suggestions to your agents so they can deliver better and faster
                        answers to your customers.</p>
                    </div>
                  </div>
                  <div className="features">
                    <span className="icon"><i className="icon-circle-compass"></i></span>
                    <div className="f-desc">
                      <p><strong>Mix the best of both worlds. Bot-Human-Bot.</strong> Live’s
                        middleware controls the flow and drifts the conversation transparently
                        to a human agent when needed, without changing the interaction window.
                        Return the conversation back to Bot at any time, maintaining a unique
                        omnichannel user experience.</p>
                    </div>
                  </div>
                  <p className="text-center">
                    <a href="https://app.sisibot.com/signup" className="btn btn-danger btn-lg shadow with-arrow">Start your digital transformation <i className="icon-arrow-right3"></i></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Clients */}
        <div className="section-block newsletter-bg resou_bg cta-block">
          <div className="container">
            <div className="newsletter-wrapper-home text-center">
              <h3 className="newsletter-title mb-5">Deliver personalized, effortless customer service.</h3>

              <div className="get-started-clearbit w-embed w-script mt-5">
                <a href="https://app.sisibot.com/signup" className="btn btn-danger shadow clearbit-overlay track-click w-button">REINVENT YOUR BUSINESS</a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LandingIndexPage;
