import React from 'react';


class AgentBotPage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="services">
                    <div className="desc">
                      <h3>Your customers use natural and informal language.</h3>
                      <h5>Let them be themselves.</h5>
                      <p>People talk naturally, even to companies. AgentBot’s natural language understanding engine
                        allows it to interpret conversational language and it learns from every interaction.</p>
                      <p>AgentBot is an Artificial Intelligence solution that integrates human empathy and human-like
                        dialogues in customer service communication.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="services">
                  <div className="desc">
                    <h3>Design incredible and valuable experiences and let the conversations flow</h3>
                    <p>When someone starts a conversation with your chatbot, you don’t want it to be a dead end.
                      With conversational flows, you’ll be able to associate nodes in order to set up the process
                      the user will follow until they get the information they’re looking for. Build a decision tree,
                      see it in a simple diagram and make the changes you want in just a few clicks.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 colorlib-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="services">
                  <div className="desc">
                    <h3>Create omnichannel experiences Anytime, anywhere.</h3>
                    <p>What channels do your customers prefer? Provide the same experience across any text or voice channel.
                      AgentBot’s middleware interacts with each application and adapts the answers to build coherent,
                      evolving conversations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="services">
                  <div className="desc">
                    <h3>Manage your knowledge simply. Easy for you, easier for them.</h3>
                    <p>Learn from your customers, train your virtual assistant without any linguistic or technical skills, and solve their requests in real time.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AgentBotPage;
