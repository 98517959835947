import React from 'react';

class NavigationHeader extends React.Component {

  render () {
    return (
      <nav className="colorlib-nav sticky-top" role="navigation">
        <div className="top-menu">
          <div className="container">
            <div className="row d-flex justify-content-between flex-row navbar-expand-md">
              <div id="colorlib-logo" className="d-flex">
                <a href="/" className="btn d-block my-auto">
                  <img src="/images/logo.svg" alt="logo" width="155" />
                </a>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-menu5 text-white"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="d-flex flex-column flex-md-row ml-auto">
                  <li className="active"><a href="/" className="btn">Home</a></li>
                  <li className="has-dropdown">
                    <a href="/services" className="btn">Services</a>
                    <ul className="dropdown">
                      <li><a href="/agent-bot">Agent bot</a></li>
                      <li><a href="/voice">Voice</a></li>
                      <li><a href="/live">Live</a></li>
                    </ul>
                  </li>
                  <li className="has-dropdown">
                    <a href="/why-us" className="btn">Company</a>
                    <ul className="dropdown">
                      <li><a href="/what-we-do">What we do</a></li>
                      <li><a href="/use-cases">Use cases</a></li>
                      <li><a href="/how-it-works">How it works</a></li>
                    </ul>
                  </li>
                  <li><a href="/pricing" className="btn">Pricing</a></li>
                  <li><a href="https://app.sisibot.com/login" className="btn">Login</a></li>
                  <li><a href="https://app.sisibot.com/signup" className="btn btn-custom btn-danger shadow text-white">Start Free</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default NavigationHeader;
