import React from 'react';
import { Outlet } from "react-router-dom";

import Navigation from '../components/navigation/_navMaintenance'
import Footer from '../components/footer/_footerMaintenance'

class Layout extends React.Component {
  render() {
    return (
      <>
        <section className="h-100">
          <Navigation {...this.props} />

          <div className="content-body">
            {/* Start Content */}
            <Outlet />
            {/* End Content */}
          </div>

          <Footer {...this.props} />
        </section>
      </>
    );
  }
}

export default Layout;
