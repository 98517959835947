import React from 'react';


class HowWorksPage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center ">
                  <div className="services">
                    <div className="desc">
                      <h3>Phase 1: information</h3>
                      <p>Your customers get all the answers and guidance they need - 24/7,
                        instantly and in any language. You automate thousands of interactions every day. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Phase 2: transaction</h3>
                    <p>You connect the virtual agent to your systems and let it perform personalized,
                      core business functions directly in chat on behalf of logged-in customers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 colorlib-services colorlib-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center ">
                <div className="services">
                  <div className="desc">
                    <h3>Phase 3: transformation</h3>
                    <p>Direct messaging has become the favourite channel of your customers.
                      The virtual agent analyses, advises, sells and up-sells based on customer data.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HowWorksPage;
