import React from 'react';


class LivePage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="services">
                    <div className="desc">
                      <h3>Be there for your customers.</h3>
                      <h5>Wherever they are, whatever they need.</h5>
                      <p>Talk to your customers on their favorite platforms, providing consistent omnichannel experiences.
                        Have real time or asynchronous conversations; Live adapts the messages to the
                        rules of each channel and the type of support.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="services">
                  <div className="desc">
                    <h3>Shed a light on your agents’ performance</h3>
                    <h5>Monitor every conversation</h5>
                    <p>Boost your customer support strategy through real-time analytics on your agents’ work as well as your clients’ satisfaction.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LivePage;
