import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import {
  MaintenanceGeneral,
  AgentBotPage,
  HowWorksPage,
  LandingIndexPage,
  LivePage,
  PricingPage,
  ServicesPage,
  UseCasesPage,
  VoicePage,
  WhatWeDoPage,
  WhyUsPage
} from './pages/index';
import {
  LayoutMaintenance,
  LayoutGeneral
} from './layouts/index';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutGeneral  />}>
          <Route index element={<LandingIndexPage />} />

          <Route path="/what-we-do" element={<WhatWeDoPage />} />
          <Route path="/why-us " element={<WhyUsPage />} />
          <Route path="/use-cases " element={<UseCasesPage />} />
          <Route path="/agent-bot " element={<AgentBotPage />} />
          <Route path="/how-it-works" element={<HowWorksPage />} />
          <Route path="/voice" element={<VoicePage />} />
          <Route path="/live" element={<LivePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/services" element={<ServicesPage />} />
        </Route>

        <Route path="*" element={<LayoutMaintenance />} >
          <Route path="*" element={<MaintenanceGeneral />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
