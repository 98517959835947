import React from 'react';

class NavigationHeader extends React.Component {

  render () {
    return (
      <nav className="nav">
        {/* TODO */}
      </nav>
    )
  }
}

export default NavigationHeader;
