import React from 'react';


class ServicesPage extends React.Component {
  render() {

    return (
      <>
        <div className="gobot-container">
          <div className="table-cell mt-5 colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-lightbulb"></i>
                    </span>
                    <div className="desc">
                      <h3>{this.props.data.locale.SERVICE01_TITLE_LABEL}</h3>
                      <p>{this.props.data.locale.SERVICE01_DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-beaker"></i>
                    </span>
                    <div className="desc">
                      <h3>{this.props.data.locale.SERVICE02_TITLE_LABEL}</h3>
                      <p>{this.props.data.locale.SERVICE02_DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center ">
                  <div className="services">
                    <span className="icon">
                      <i className="icon-layers"></i>
                    </span>
                    <div className="desc">
                      <h3>{this.props.data.locale.SERVICE03_TITLE_LABEL}</h3>
                      <p>{this.props.data.locale.SERVICE03_DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesPage;
